import { User } from 'src/api/base/user/user';
import { Component, OnInit } from '@angular/core';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ImportadorService } from 'src/services/importador.service';
import { UserService } from 'src/api/base/user/user.service';
import { ImportData } from 'src/Models/importData';
import { PersonOrganizationService } from 'src/services/personOrganization.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ImportChallenge',
  templateUrl: './ImportChallenge.component.html',
  styleUrls: ['./ImportChallenge.component.css']
})
export class ImportChallengeComponent implements OnInit {

  form!: FormGroup;

  isLoading: boolean = false;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  importData: ImportData = {};

  label: string = 'Desafios';
  url = environment.root;
  user!: User;
  organizationUser: any;

  constructor(private _dialog: MatDialog,
    private _user: UserService,
    private _personOrganization: PersonOrganizationService,
    private _toastr: ToastrService,
    private importadorService: ImportadorService) { 
    
  }

  ngOnInit() {
    this.user = this._user.obterUsuario();
    this.getData();

    this.initializeForm();
  }

  getData(){
    this._personOrganization.getPersonOrganizationByPerson(this.user.id).subscribe(
      (response) => {
        this.organizationUser = response;
      },
      (error) => {
        console.error('Error getting organization user', error);
      }
    );
  }

  initializeForm() {
    this.form = new FormGroup({
      file: new FormControl(null, [Validators.required]),
      sheetName: new FormControl('', [Validators.required])
      
    });
  }

  onFileChange(event: any): void {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      this.form.patchValue({
        file: file  // Make sure to bind the file control to the file directly
      });
      // Optionally, you can manually update UI components or validations here
      this.form.get('file')?.updateValueAndValidity();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.errorMessage = null;
      this.successMessage = null;
      this.importData = {
        ownerId: this.user.id,
        ownerName: this.user.nome,
        organizationId: this.organizationUser.organizationId,
        userProblemEmail: this.user.email,
        userProblemPhone: this.user.telefone,
      }


  
    const file = this.form.get('file')?.value;
    const sheetName = this.form.get('sheetName')?.value;

    // Check if the file exists and is a File object
    if (!(file instanceof File)) {
      console.error('The file is missing or not a file:', file);
      this.isLoading = false;
      return;
    }
  
      this.importadorService.importChallenges(file, sheetName, this.importData).subscribe(
        (response) => {
          this.isLoading = false;
          this.successMessage = 'Arquivo importado com sucesso!';
          //close the dialog and toast a message
          
          this._toastr.success('Arquivo importado com sucesso!', 'Sucesso');
          
        },
        (error) => {
          this.isLoading = false;
          this.errorMessage = 'Erro ao importar arquivo. Por favor, tente novamente.';
          console.error('Error uploading file', error);
        }
      );
    }
  }

  onCancel() {
    this.form.reset();

  }



}

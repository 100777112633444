<h2 mat-dialog-title>Importação de {{label}}</h2>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group row">
      <!-- Label for File Input -->
      <div class="left-column col-sm-4 col-form-label">
        <label for="file" class="file-label">Arquivo <span class="required-indicator">*</span></label>
      </div>

      <!-- File Input -->
      <div class="col-sm-8">
        <input type="file" class="form-control" id="file" formControlName="file" (change)="onFileChange($event)">
        <small *ngIf="form.get('file')?.value" class="file-info">
          Selecionado: {{ form.get('file')?.value?.name }}
        </small>
      </div>
    </div>

    <!-- Sheet Name Input -->
    <div class="form-group row">
      <div class="left-column col-sm-4 col-form-label">
        <label for="sheetName" class="file-label">Nome da Planilha <span class="required-indicator">*</span></label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control" id="sheetName" formControlName="sheetName" placeholder="Ex.: Planilha1">
      </div>
    </div>

    <!-- File Model Info -->
    <div class="file-model-info">
      <h4 class="file-model-title">Modelo de arquivo para upload</h4>
      <a href="{{url}}files/Challenge_Form_Template.xlsx"  target="_blank" class="file-model-link">
        <img mat-mini-fab src="/assets/iconeExcel.png" class="file-model-icon" alt="excel" style="cursor:pointer;">
        <p class="file-model-text">É mandatório o uso deste modelo de arquivo para upload</p>
      </a>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onCancel()" mat-dialog-close>Cancelar</button>
  <button mat-button [disabled]="!form.valid || isLoading" (click)="onSubmit()">Importar</button>
</mat-dialog-actions>

<!-- Loading Spinner -->
<div *ngIf="isLoading" class="loading-spinner">
  <mat-spinner></mat-spinner>
  <p>Importando...</p>
</div>

<!-- Feedback Messages -->
<div *ngIf="errorMessage" class="error-message">
  <p>{{ errorMessage }}</p>
</div>
<div *ngIf="successMessage" class="success-message">
  <p>{{ successMessage }}</p>
</div>

import { PersonOrganizationRequest } from './../Models/PersonOrganizationRequest';
import { PersonOrganizationDTO } from './../Models/PersonOrganizationDTO';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonOrganizationService {

constructor(private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) { }

  public addPersonOrganization(personOrganization: PersonOrganizationRequest): Observable<PersonOrganizationRequest> {
    return this.http.post<PersonOrganizationRequest>(`${this.baseUrl}api/personOrganization`, personOrganization);
  }

  public getAll(): Observable<PersonOrganizationDTO[]> {
    return this.http.get<PersonOrganizationDTO[]>(`${this.baseUrl}api/personOrganization`);
  }

  public getById(id: number): Observable<PersonOrganizationDTO> {
    return this.http.get<PersonOrganizationDTO>(`${this.baseUrl}api/personOrganization/${id}`);
  }

  public getPersonOrganizationByPerson(personId: number): Observable<PersonOrganizationDTO[]> {
    return this.http.get<PersonOrganizationDTO[]>(`${this.baseUrl}api/personOrganization/person/${personId}`);
  }

  public getPersonByOrganization(organizationId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/personOrganization/organization/${organizationId}`);
  }

  public getOwnerIdByOrganization(organizationId: number): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}api/personOrganization/ownerIdByResponsibleOrganization?organizationId=${organizationId}`);
  }

  public deletePersonOrganization(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}api/personOrganization/${id}`);
  }
  
  public updatePersonOrganization(personOrganization: PersonOrganizationRequest): Observable<PersonOrganizationRequest> {
    return this.http.put<PersonOrganizationRequest>(`${this.baseUrl}api/personOrganization/`, personOrganization);
  }

  public updatePersonOrganizationByPersonId(personOrganization: PersonOrganizationRequest): Observable<PersonOrganizationRequest> {
    return this.http.put<PersonOrganizationRequest>(`${this.baseUrl}api/personOrganization/Person`, personOrganization);
  }

}

import { ImportData } from './../Models/importData';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImportadorService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

    importSolutions(file: File, sheetName: string, importData?: ImportData){
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sheetName', sheetName);
      debugger;
      if(importData){
        formData.append('dataUserSerialized', JSON.stringify(importData));
      }

      for (let [key, value] of (formData as any).entries()) {
        console.log(`${key}: ${value}`);
      }

      return this.http.post(`${this.baseUrl}api/importador/solutions`, formData);
    }

    importChallenges(file: File, sheetName: string, importData?: ImportData){
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sheetName', sheetName);
      if(importData){
        formData.append('dataUserSerialized', JSON.stringify(importData));
      }

      return this.http.post(`${this.baseUrl}api/importador/problems`, formData);
    }
}

import { PersonSolutionService } from './../../../services/personSolution.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faArchive, faCommentAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Solution } from 'src/Models/solution';
import { AccessService } from 'src/services/access.service';
import { DialogService } from 'src/services/dialog.service';
import { SolutionService } from 'src/services/solution.service';

@Component({
  selector: 'app-MySolutions',
  templateUrl: './MySolutions.component.html',
  styleUrls: ['./MySolutions.component.css']
})
export class MySolutionsComponent implements OnInit {
  title = 'Minhas Soluções';
  faCommentAlt = faCommentAlt;
  faEdit = faEdit;
  faArchive = faArchive;
  solutions = new MatTableDataSource<any>();
  displayedColumns: string[] = 
  [
    'solutionId',
    'organizationName',
    // 'objectives',
    'area',
     'typeOfSolution', 
     'description', 
     'trl', 
      'status',
     'actions'
  ];
  user: any;
  

  constructor(private _solution: SolutionService,
    private _solutions: SolutionService,
    private _personSolution: PersonSolutionService,
    private dialog: DialogService,
    private _access: AccessService,
    private router: Router) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = JSON.parse(localStorage.getItem('app.user')!); 
    this.loadData();
  }

  loadData() {
    this._solutions.getSolutionByPerson(this.user.id)
    .subscribe(result =>{
      this.solutions = new MatTableDataSource<any>(result);
      debugger;
      console.log(result);
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.solutions.filter = filterValue.trim().toLowerCase();
  }

  openDialog(element?: any) {
  this.router.navigate(['/newSolution']);

  }
delete(element: any) {
    this._solution.deleteSolution(element.solutionId).subscribe(
      () => {
        console.log('Solution deleted successfully!');
        this.loadData();
      },
      error => {
        console.log(error);
      }
    )
  }

  archiveSolution(element: any) {
    if(element.status == true){
      this.dialog.confirmDialog('Inativar Solução', 'Deseja realmente inativar esta solução?').subscribe(
      (result: any) => {
        if(result){
          let data = element;
          data.status = !data.status;
            this._solution.updateSolution(data, element.solutionId).subscribe(
              () => {
                console.log('Solution archived successfully!');
                this.loadData();
              },
              error => {
                console.log(error);
              }
            )
          }
        }
          )
        }
    else {
      this.dialog.confirmDialog('Ativar Solução', 'Deseja realmente ativar esta solução?').subscribe(
      (result: any) => {
        if(result){
          let data = element;
          data.status = !data.status;
            this._solution.updateSolution(data, element.solutionId).subscribe(
              () => {
                console.log('Solution activated successfully!');
                this.loadData();
              },
              error => {
                console.log(error);
              }
            )
          }
        }
      )
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Solution } from 'src/Models/solution';

@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  public addSolution(solution: any): Observable<Solution> {
    return this.http.post<Solution>(`${this.baseUrl}api/solution`, solution);
  }

  public getAll(): Observable<Solution[]> {
    return this.http.get<Solution[]>(`${this.baseUrl}api/solution`);
  }

  public getAllSolutionsResponse(): Observable<Solution[]> {
    return this.http.get<Solution[]>(`${this.baseUrl}api/solution/Response`);
  }

  public getById(id: number): Observable<Solution> {
    return this.http.get<Solution>(`${this.baseUrl}api/solution/${id}`);
  }

  public getSolutionByPerson(personId: number): Observable<Solution[]> {
    return this.http.get<Solution[]>(`${this.baseUrl}api/solution/person/${personId}`);
  }

  public updateSolution(solution: any, id: number): Observable<Solution> {
    return this.http.put<Solution>(this.baseUrl + 'api/solution/' + id, solution);
  }

  public deleteSolution(id: number): Observable<Solution> {
    return this.http.delete<Solution>(this.baseUrl + 'api/solution/' + id);
  }
}

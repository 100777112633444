import { NgxSpinnerService } from 'ngx-spinner';
import { Solution } from 'src/Models/solution';
import { DialogService } from './../../../services/dialog.service';
import { Validator, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SolutionService } from 'src/services/solution.service';
import { UserService } from 'src/api/base/user/user.service';
import { FormationArea } from 'src/Models/formationArea';
import { FormationAreaService } from 'src/services/formationArea.service';
import { ActivatedRoute } from '@angular/router';
import { AccessService } from 'src/services/access.service';
import { PersonService } from 'src/services/person.service';

@Component({
  selector: 'app-InsertSolution',
  templateUrl: './InsertSolution.component.html',
  styleUrls: ['./InsertSolution.component.css']
})
export class InsertSolutionComponent implements OnInit {
  solutionForm!: FormGroup;
  id!: number;
  solution!: Solution;
  label: string = 'Cadastrar Solução';
  user: any;
  organization: any;
  person: any;

  trl = [
    { value: '0', label: 'IDEIA', classificacao: 'Ideia', descricao: 'Nascimento da Ideia (Conceito não comprovado. Nenhum teste foi realizado)' },
    { value: '1', label: 'CONCEITO', classificacao: 'Pesquisa Básica', descricao: 'Início das pesquisa, observação e primeiros relatos (identificação do lastro de conhecimento) {Agora você pode descrever a(s) necessidade(s), mas não tem evidências}' },
    { value: '2', label: 'CONCEITO', classificacao: 'Formulação da Tecnologia', descricao: 'Conceito e aplicação formuladas (concepção de possíveis aplicações) {PRECISA DE VALIDAÇÃO - Você tem uma oferta inicial: as partes interessadas gostam do seu slideware}' },
    { value: '3', label: 'CONCEITO', classificacao: 'PoC - Prova de Conceito', descricao: 'Pronta a POC - Prova de Conceito e aplicação experimental (Testes laboratoriais e Prova de Conceito) {Conceito e aplicação foram formulados}' },
    { value: '4', label: 'PROTÓTIPO', classificacao: 'Teste em Laboratório', descricao: 'Protótipo funcionalidade (Validação da tecnologia em ambiente controlado) {construído em laboratório}' },
    { value: '5', label: 'PROTÓTIPO', classificacao: 'Validação em Ambiente Simulado', descricao: 'Aplicação dentro de uma ambiente simulado e controlado (requerimento mínimo) (Modelo validado em ambiente simulado) {Testado no ambiente pretendido}' },
    { value: '6', label: 'DEMONSTRAÇÃO', classificacao:  'Piloto em Ambiente Relevante', descricao: 'Testado e demonstrado em um ambiente próximo/similar ao real e atingiu a performance esperada (situação próxima do desempenho esperado) {Testado no ambiente pretendido próximo ao desempenho esperado}' },
    { value: '7', label: 'DEMONSTRAÇÃO', classificacao: 'Demonstração em Ambiente Operacional', descricao: 'Testado e demonstrado no ambiente real e atingiu a performance esperada (Protótipo analisado em ambiente operacional) {Operando em ambiente operacional em escala pré-comercial}' },
    { value: '8', label: 'PRODUÇÃO', classificacao: 'Produto em Produção', descricao: 'Todos as funções técnicas do produto estão finalizadas, testadas e o suporte comercial pronto para venda (tecnologia pronta e validada em ambiente real) { Todos os processos e sistemas técnicos de apoio à atividade comercial em estado pronto}' },
    { value: '9', label: 'MERCADO', classificacao: 'Produto à venda no Mercado', descricao: 'Produto pronto, em uso e/ou gerando vendas (O Produto está pronto para ir para o mercado) {Em Produção - Aplicação Comercial Completa}' },
  ]

  contributionTypes = [
    { value: 'author', label: 'Autor' },
    { value: 'co-author', label: 'Co-Autor' },
    { value: 'colaborator', label: 'Colaborador' },
  ];
  formationAreas!: { classification: any; items: any; }[];
  constructor(private fb: FormBuilder,
    private _user: UserService,
    private _dialog: DialogService,
    private _formationArea: FormationAreaService,
    private _activatedRoute: ActivatedRoute,
    private _spinner: NgxSpinnerService,
    private _access: AccessService,
    private _solution: SolutionService,
    private _person: PersonService) { }

  ngOnInit() {
    this._access.checkIfItsLogedIn();
    this.user = this._user.obterUsuario();
    this.organization = JSON.parse(localStorage.getItem('organizationUser') || '{}');
    this.initializeForm();
    this.getFormationAreas();
  }

  initializeForm() {
    this.solutionForm = this.fb.group({
        organizationResponsibleName: [this.organization.name, Validators.required],
        typeOfSolution: ['', Validators.required],
        description: ['', Validators.required],
        mvpFeat: ['', Validators.required], //categoria específica
        operationalGain: [''],
        annex: [''],
        economicGain: ['', Validators.required],
        objectives: [''],
        patentNumber: [''],
        contributionType: [''],
        trl: ['', Validators.required],
        availableFees: ['', Validators.required],
        urlPresentationData: [''],
        videoLink: [''],
    });
    this.loadData();
  }

  loadData() {
   this.id = this._activatedRoute.snapshot.params['id'];
    if(this.id) {
      this._solution.getById(this.id).subscribe(result => {
        this.solution = result;
        this.solutionForm.patchValue(this.solution);
      });
      this.label = 'Editar Solução';
    }
    
  }

  onSubmit() {
    this._spinner.show();
    let isProfileComplete = this._access.checkIfProfileIsComplete();
    if (!isProfileComplete) {
      this._dialog.openDialog('Erro', 'Para cadastrar uma solução é necessário completar o perfil', 'error');
      this._spinner.hide();
      return;
    } else {
      this.saveSolution();

    }
  }

  saveSolution() {
    // Primeiro, buscar os dados da pessoa relacionada ao usuário atual
    this._person.getById(this.user.id).subscribe({
      next: result => {
        this.person = result;
  
        // Capturar e processar os dados do formulário
        const rawFees = this.solutionForm.get('availableFees')?.value;
        const parsedFees = parseFloat(rawFees.replace(/[^\d.-]/g, '')); // Remove caracteres inválidos e converte para número
  
        // Criar o objeto solução
        const solution = {
          organizationResponsibleId: this.user.id,
          organizationResponsibleName: this.solutionForm.get('organizationResponsibleName')?.value,
          typeOfSolution: this.solutionForm.get('typeOfSolution')?.value,
          description: this.solutionForm.get('description')?.value,
          economicGain: this.solutionForm.get('economicGain')?.value,
          objectives: this.solutionForm.get('objectives')?.value,
          operationalGain: this.solutionForm.get('operationalGain')?.value,
          trl: this.solutionForm.get('trl')?.value,
          mvpFeat: this.solutionForm.get('mvpFeat')?.value,
          availableFees: parsedFees,
          urlPresentation: this.solutionForm.get('urlPresentation')?.value,
          videoLink: this.solutionForm.get('videoLink')?.value,
          isOrganizationSolution: false,
          contributionType: this.solutionForm.get('contributionType')?.value,
          status: true,
          userSolutionPhone: this.person.phoneNumber,
          userSolutionEmail: this.person.email
        };
  
        // Verificar se é uma atualização ou criação
        if (this.id) {
          Object.assign(solution, { solutionId: this.id }); // Adicionar o ID da solução no objeto para atualização
          this._solution.updateSolution(solution, this.id).subscribe({
            next: () => {
              this._dialog.openDialog('Sucesso', 'Solução atualizada com sucesso!', 'success');
              this._spinner.hide();
            },
            error: err => {
              console.error('Erro ao atualizar a solução:', err);
            }
          });
        } else {
          this._solution.addSolution(solution).subscribe({
            next: () => {
              this._dialog.openDialog('Sucesso', 'Solução cadastrada com sucesso!', 'success');
              this.solutionForm.reset(); // Resetar o formulário após o sucesso
              this._spinner.hide();
            },
            error: err => {
              console.error('Erro ao cadastrar a solução:', err);
            }
          });
        }
      },
      error: err => {
        console.error('Erro ao buscar dados da pessoa:', err);
        this._dialog.openDialog('Erro', 'Não foi possível carregar os dados do usuário.', 'error');
      }
    });
  }
  
  
  addNewColaborator(){
    this._dialog.openAddOrganizationAdmin(this.organization.organizationId, this.id);
  }

  getFormationAreas() {
    this._formationArea.getAll().subscribe(result => {
      this.formationAreas = this.groupFormationAreas(result);
    });
  }
  
  groupFormationAreas(areas: any[]) {
    const grouped = new Map();
  
    areas.forEach((area) => {
      const classification = grouped.get(area.classification) || [];
      classification.push(area);
      grouped.set(area.classification, classification);
    });
  
    // Sort each group (classification) alphabetically by item name
    grouped.forEach((value, key) => {
      value.sort((a: FormationArea, b: FormationArea) => {
        return a.name.localeCompare(b.name);
      });
    });
  
    // Convert Map to array and sort the classifications alphabetically
    const sortedGrouped = Array.from(grouped, ([classification, items]) => ({
      classification,
      items
    })).sort((a, b) => a.classification.localeCompare(b.classification));
  
    return sortedGrouped;
  }

  //Importing Solution
  openDialog() {
    this._dialog.importSolutionData(this.solutionForm.value);
  }

}

import { MatDialog } from '@angular/material/dialog';
import { TypeOrganizationService } from './../../../services/typeOrganization.service';
import { UserRoleService } from './../../../services/userRole.service';
import { DialogService } from 'src/services/dialog.service';
import { Component, OnInit, Type } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Organization } from 'src/Models/organization';
import { OrganizationService } from 'src/services/organization.service';
import { UserRoleAssignmentModel } from 'src/Models/UserRoleAssignmentModel';
import { TypeOrganizationDialogComponent } from 'src/app/shared/TypeOrganizationDialog/TypeOrganizationDialog.component';
import { PersonOrganizationRequest } from 'src/Models/PersonOrganizationRequest';
import { PersonOrganizationService } from 'src/services/personOrganization.service';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'app-ApproveOrganization',
  templateUrl: './ApproveOrganization.component.html',
  styleUrls: ['./ApproveOrganization.component.css']
})
export class ApproveOrganizationComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'emailResponsible', 'phoneNumber', 'status', 'actions'];
  dataSource!: MatTableDataSource<Organization>;
  typeOrganizations: any;
  personOrgToEdit: any;
  ownerId: any;
  personOrganizationReq = <PersonOrganizationRequest>{};

  constructor(
    private organizationService: OrganizationService,
    private _userRole: UserRoleService,
    private _typeOrganization: TypeOrganizationService,
    private dialog: MatDialog,
    private _dialog: DialogService,
    private _personOrganization: PersonOrganizationService
  ) {}

  ngOnInit() {
   this.getOrganizations();
   this.getOrganizationType(); 
  }


  getOrganizations() {
    this.organizationService.getAll().subscribe(organizations => {
      // Filtra organizações com status 'Pendente' ou 'Inativo'
      const filteredOrganizations = organizations.filter(org => 
        org.status === 'Pendente' || org.status === 'Inativo'
      );
      this.dataSource = new MatTableDataSource(filteredOrganizations);
    });
  }
  

  getOrganizationType() {
    this._typeOrganization.getAll().subscribe(typeOrganizations => {
      this.typeOrganizations = typeOrganizations;
    });
  }

  
  approveOrganization(organization: Organization, id: number) {
    const dialogRef = this.dialog.open(TypeOrganizationDialogComponent, {
      width: '450px',
      data: { typeOrganizations: this.typeOrganizations }
    });
    
    this._personOrganization.getOwnerIdByOrganization(id).subscribe((result => {
      this.ownerId = result;
    }
    ));

    this._personOrganization.getOwnerIdByOrganization(id).pipe(
      switchMap(ownerId => {
        this.ownerId = ownerId;
        return this._personOrganization.getPersonOrganizationByPerson(this.ownerId);
      })
    ).subscribe(personOrg => {
      if (personOrg && personOrg.length > 0) {
        this.personOrgToEdit = personOrg[0];
      } else {
        this.personOrgToEdit = null; 
      }
    });

    dialogRef.afterClosed().subscribe(selectedTypeOrganizationId => {
      if (selectedTypeOrganizationId) {
        if (this.personOrgToEdit) {
          organization.typeOrganizationId = selectedTypeOrganizationId;
          organization.status = 'Ativo';
          organization.dataDaAtivacao = new Date();
          organization.dataUltimaAlteracao = new Date();
    
          this.organizationService.updateOrganization(organization, id).subscribe(() => {
            this.setUserAsOwner();
          
    
          if (organization.status == 'Ativo') {
            debugger;
            this.personOrganizationReq = {
              personId: this.ownerId,
              organizationId: id,
              isAdmin: false,
              isOwner: true,
              isBlocked: false,
              isDeleted: false,
              isMember: false
            };
            this._personOrganization.updatePersonOrganizationByPersonId(this.personOrganizationReq).subscribe();
          }
        });
          this._dialog.openDialog('Sucesso', 'Empresa aprovada com sucesso', 'success');
        } else {
          organization.typeOrganizationId = selectedTypeOrganizationId;
          organization.status = 'Ativo';
          organization.dataDaAtivacao = new Date();
          organization.dataUltimaAlteracao = new Date();
    
          this.organizationService.updateOrganization(organization, id).subscribe(() => {
            this.setUserAsOwner();
            this._dialog.openDialog('Sucesso', 'Empresa aprovada com sucesso', 'success');
          });
    
          if (organization.status == 'Ativo') {
            this.personOrganizationReq = {
              personId: this.ownerId,
              organizationId: id,
              isAdmin: false,
              isOwner: true,
              isBlocked: false,
              isDeleted: false,
              isMember: false
            };
            this._personOrganization.addPersonOrganization(this.personOrganizationReq).subscribe();
          }
        }
      }
    });
  }    

  // private updatePersonOrganization(organization: Organization, id: number, selectedTypeOrganizationId: any) {
  //   organization.typeOrganizationId = selectedTypeOrganizationId;
  //   organization.status = 'Ativo';
  //   organization.dataDaAtivacao = new Date();
  //   organization.dataUltimaAlteracao = new Date();

  //   return this.organizationService.updateOrganization(organization, id).pipe(
  //     switchMap(() => {
        
  //       this.personOrganizationReq = {
  //         personId: this.ownerId,
  //         organizationId: id,
  //         isAdmin: false,
  //         isOwner: true,
  //         isBlocked: false,
  //         isDeleted: false,
  //         isMember: false
  //       };
        
  //       this.setUserAsOwner();
  //       return this._personOrganization.updatePersonOrganizationByPersonId(this.personOrganizationReq);
  //     })
  //   );
  // }
  
  // private updateAndActivateOrganization(organization: Organization, id: number, selectedTypeOrganizationId: any) {
  //   organization.typeOrganizationId = selectedTypeOrganizationId;
  //   organization.status = 'Ativo';
  //   organization.dataDaAtivacao = new Date();
  //   organization.dataUltimaAlteracao = new Date();

  //   return this.organizationService.updateOrganization(organization, id).pipe(
  //     switchMap(() => {
        
  //       this.personOrganizationReq = {
  //         personId: this.ownerId,
  //         organizationId: id,
  //         isAdmin: false,
  //         isOwner: true,
  //         isBlocked: false,
  //         isDeleted: false,
  //         isMember: false
  //       };
        
  //       this.setUserAsOwner();
  //       return this._personOrganization.addPersonOrganization(this.personOrganizationReq);
  //     })
  //   );
  // }

denyOrganization(organization: Organization, id: number) {
  organization.status = 'Inativo';
  organization.dataDaDesativacao = new Date();
  this.organizationService.updateOrganization(organization, id).subscribe((result) => {
    console.log(result);
    this._dialog.openDialog('Sucesso', 'Empresa rejeitada com sucesso', 'success');
  });
}


private setUserAsOwner() {
  const user = JSON.parse(localStorage.getItem('app.user') || '{}');

  if (user) {
    //bring the sub in claims to store as a userId
    let userId = user.claims.find((c: any) => c.type === 'sub')?.value;
    let roleId = 'c4316007-99ac-4c8c-8092-d2ddc042e18e';
    let data = <UserRoleAssignmentModel>{};
    data.userId = userId;
    data.roleId = roleId;
    this._userRole.assignUserRole(data).subscribe(res => console.log(res));
}
}

}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

constructor(private http: HttpClient,
  @Inject('BASE_URL') private baseUrl: string) { }

addProposal(proposal: any): Observable<any>{
  return this.http.post<any>(`${this.baseUrl}api/proposals`, proposal);
}

editProposal(proposal: any): Observable<any>{
  return this.http.put<any>(`${this.baseUrl}api/proposals`, proposal);
}

deleteProposal(proposalId: number): Observable<any>{
  return this.http.delete<any>(`${this.baseUrl}api/proposals/${proposalId}`);
}

getProposal(proposalId: number): Observable<any>{
  return this.http.get<any>(`${this.baseUrl}api/proposals/${proposalId}`);

}

getProposalReceived(ownerId: number): Observable<any>{
  return this.http.get<any>(`${this.baseUrl}api/proposals/received/${ownerId}`);
}

getProposalByChallenge(challengeId: number): Observable<any>{
  return this.http.get<any>(`${this.baseUrl}api/proposals/problem/${challengeId}`);
}

getProposalBySolution(solutionId: number): Observable<any>{
  return this.http.get<any>(`${this.baseUrl}api/proposals/solution/${solutionId}`);
}

getProposalsBySolutionResponsible(organizationResponsibleId: number): Observable<any> {
  return this.http.get<any>(`${this.baseUrl}api/proposals/solution-responsible/${organizationResponsibleId}`);
}

private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // Client-side or network error
    console.error('An error occurred:', error.error.message);
  } else {
    // Backend returned an unsuccessful response code
    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
  }
  // Return an observable with a user-facing error message
  return throwError('Something bad happened; please try again later.');
}

}